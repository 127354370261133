const oddRequestUpdate = 'odd-request-update';
const vehicleStatus = 'vehicle-status';
const sensorData = 'sensor-data';
const eventStatus = 'event-status';
const incidentStatus = 'incident-status';
const cloudTelephony = 'cloud-telephony';
const liveStreaming = 'live-streaming';
const serviceHandler = 'service-handler';
const commandHandler = 'command-handler';
const actionableEvents = 'actionable-events';

export {
    oddRequestUpdate, vehicleStatus, sensorData, eventStatus,
    incidentStatus, cloudTelephony, liveStreaming, serviceHandler,
    commandHandler, actionableEvents
};
    
export const WebSocketNotificationEvents = {
    UNAUTHORIZED_DRIVER: 'UNAUTHORIZED_DRIVER',
    GEOFENCE: 'GEOFENCE',
    NEW_EVENT: 'NEW_EVENT',
    NEW_ALERT: 'NEW_ALERT',
    ACTIONABLE_EVENT: 'ACTIONABLE_EVENT'
}
