import moment from 'moment';
import {
  GET_MASTER_DATA,
  GET_EVENT_DATA,
  FILTER_EVENT_DATA,
  GET_ODD_VEHICLE_LIST,
  GET_ODD_REQUESTED_DATA,
  CLEAR_ODD_REQUESTED_DATA,
  ODD_REQUEST_UPDATE,
  ESTABLISHED_WS_CONNECTION_SUCCESS,
  GET_CLIENT_LIST,
  GET_ADMIN_PAGE_CLIENT_LIST,
  GET_DEVICE_LIST,
  GET_SAFEST_RISKIEST_DRIVER_LIST,
  UPDATE_SENSOR_DATA,
  UPDATE_EVENT_DATA,
  UPDATE_SELECTED_VEHICLE,
  CLEAR_SAFEST_RISKIEST_DRIVER_LIST,
  CLEAR_DATA,
  GET_ALL_CLIENT_LIST,
  GET_GLOBAL_CLIENT_ID,
  SET_GLOBAL_CLIENT_ID,
  SET_CLIENT_PREFERENCES,
  NOTIFICATION_DATA,
  SET_RAW_VIDEO_AVAILABILITY,
  CLEAR_RAW_VIDEO_AVAILABILITY,
  CLEAR_MULTICAMERA_AVAILABILITY,
  SET_MULTICAMERA_AVAILABILITY,
  LOAD_MULTICAMERA_AVAILABILITY,
  SET_DRIVER_COACHING_DATA,
  SET_AUDIO_CALL_PROGRESS,
  CLOUD_TELEPHONY_DEVICE_STATUS,
  LIVE_STREAMING_STATUS,
  VEHICLE_CONFIGURATION,
  SERVICE_HANDLER_STATUS,
  COMMAND_HANDLER_STATUS,
  GET_CLIENT_GROUP_LIST,
  ACTIONABLE_EVENT
} from 'redux/actions/details/actionTypes';
import { momentWithTimeZone } from 'utils/dbaiLocalizationHelper';

const initialState = {
  errorResponse: {},
  masterData: {
    driverList: {
      data: [],
      metadata: {}
    },
    vehicleList: {
      data: [],
      metadata: {}
    },
    driverCoaching: {},
    eventType:[],
    eventCategoryMap: {},
    eventTagsList: []
  },
  event: {
    eventList: {},
    filter: {
      startDate: momentWithTimeZone().subtract(4, 'days'),
      endDate: momentWithTimeZone(),
      validFilter: true,
      videoAvailability: true,
      coachable:'all',
      order: 'DESC'
    },
  },
  notifications: [],
  clientID: '',
  socket: { isConnected: false },
  allClients: [],
  clients: {
    data: [],
    metadata: {}
  },
  adminClientList: {},
  clientPreferences: {},
  configEditor : {
    commonKeys: {},
  },
  rawVideoData: [],
  multiCamData: {},
  callDetails: {
    initiated: false,
    connected: false,
    inProgress: false,
    vehicleId: '',
    duration: 0,
    callId: ''
  },
  deviceCallDetails: {},
  liveStreamDetails: {},
  vehicleConfiguration: {},
  serviceHandlerData: {},
  commandHandlerData: {},
};

const app = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_MASTER_DATA:
      const { masterData } = action;
      return { ...state, masterData: { ...(state?.masterData ?? {}), ...masterData } };
    case GET_EVENT_DATA:
      const { eventList } = action;
      return { ...state, event: { ...state.event, eventList } };
    case FILTER_EVENT_DATA:
      const { filter } = action;
      return { ...state, event: { ...state.event, filter } };
    case GET_ODD_VEHICLE_LIST:
      const { oddVehicles } = action;
      return { ...state, preview: { ...(state?.preview ?? {}), oddVehicles } };
    case GET_ODD_REQUESTED_DATA:
      const { oddRequestList } = action;
      return { ...state, preview: { ...(state?.preview ?? {}), oddRequestList } };
    case CLEAR_ODD_REQUESTED_DATA:
      return { ...state, preview: { ...(state?.preview ?? {}), oddRequestList: {} } };
    case ODD_REQUEST_UPDATE:
      const { oddRequestsStatus } = action;
      return { ...state, preview: { ...(state?.preview ?? {}), oddRequestsStatus } };
    case ESTABLISHED_WS_CONNECTION_SUCCESS:
      const { isConnected } = action;
      return { ...state, socket: { ...state.socket, isConnected } };
    case GET_CLIENT_LIST:
      const { clients } = action;
      return { ...state, clients };
    case GET_CLIENT_GROUP_LIST:
      const { groups } = action;
      return { ...state, groups };
    case GET_ALL_CLIENT_LIST:
      const { allClients } = action;
      return { ...state, allClients };
    case GET_ADMIN_PAGE_CLIENT_LIST:
      const { adminClientList } = action;
      return {...state, adminClientList};
    case GET_DEVICE_LIST:
      const { devices } = action;
      return { ...state, devices };
    case GET_SAFEST_RISKIEST_DRIVER_LIST:
      const { safestRiskiestDriver } = action;
      return { ...state, safestRiskiestDriver };
    case CLEAR_SAFEST_RISKIEST_DRIVER_LIST:
      return { ...state, safestRiskiestDriver: {} };
    case UPDATE_SENSOR_DATA:
      const { sensorData } = action;
      return { ...state, tracking: { ...state?.tracking, sensorData } };
    case UPDATE_SELECTED_VEHICLE:
      const { vehicleId } = action;
      return { ...state, tracking: { ...state?.tracking, selectedVehicle: vehicleId } };
    case UPDATE_EVENT_DATA:
      // Replace event data if exists
      const { eventUpdate } = action;
      const eventUpdates = [...(state?.event?.eventUpdates ?? [])];

      let existingEventIndex = eventUpdates.findIndex(event => {
        return event.id === eventUpdate?.id
      });
      if(existingEventIndex !== -1) {
        // isNewEvent prop not related to eventUpdates[0]
        // This is just to enable sound for new event
        eventUpdates[0].isNewEvent = false;
        eventUpdates[existingEventIndex] = eventUpdate;
      } else {
        eventUpdates.unshift(eventUpdate);
        eventUpdates[0].isNewEvent = true;
      }

      return {
        ...state,
        event: { ...state?.event, eventUpdates },
      };
    
    case NOTIFICATION_DATA:
      const {notification} = action;

      const notifications = [...(state?.notifications ?? [])];

      let existingNotificationIndex = notifications?.findIndex(notif => {
        return notification.id === notif?.id
      });

      // if(existingNotificationIndex !== -1) {
      //   notifications[0].isNewNotification = false;
      //   notifications[existingNotificationIndex] = notification;
      // } else {
      //   notifications?.unshift(notification);
      //   notifications[0].isNewNotification = true;
      // };

      notifications?.unshift({...notification, key: new Date().getTime()});


      return {
        ...state,
        notifications: [...notifications],
      }  
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    case SET_GLOBAL_CLIENT_ID:
    const { clientID } = action;
    return {
      ...state,
      clientID: clientID,
      }
    case SET_CLIENT_PREFERENCES:
      const { clientConfig } = action;
      return { ...state, clientPreferences: clientConfig };
    case SET_RAW_VIDEO_AVAILABILITY:
      const { videoData } = action;
      return { ...state, rawVideoData: videoData };
    case SET_AUDIO_CALL_PROGRESS:
      const { callDetails } = action;
      return { ...state, callDetails: {...state.callDetails, ...callDetails} };
    case CLOUD_TELEPHONY_DEVICE_STATUS:
      const { deviceData } = action;
      return { ...state, deviceCallDetails: deviceData };
    case ACTIONABLE_EVENT:
      const { actionableEvent } = action;
      return { ...state, actionableEvent: actionableEvent };
    case LIVE_STREAMING_STATUS:
      const { data } = action;
      return { ...state, liveStreamDetails: {...state?.liveStreamDetails, [data?.vehicleId] : data} };
    case SERVICE_HANDLER_STATUS:
      const { serviceHandlerData } = action;
      return { ...state, serviceHandlerData: serviceHandlerData };
    case COMMAND_HANDLER_STATUS:
      const { commandHandlerData } = action;
      return { ...state, commandHandlerData: commandHandlerData };
    case VEHICLE_CONFIGURATION:
      const { vehicleConfiguration } = action;
      return { ...state, vehicleConfiguration: vehicleConfiguration };
    case CLEAR_RAW_VIDEO_AVAILABILITY:
      return { ...state, rawVideoData: [] };
    case LOAD_MULTICAMERA_AVAILABILITY:
      return { ...state, multiCamData: {loading: true} };
    case SET_MULTICAMERA_AVAILABILITY:
      const { multiCamData } = action;
      return { ...state, multiCamData: { ...multiCamData, loading: false } };
    case CLEAR_MULTICAMERA_AVAILABILITY:
      return { ...state, multiCamData: { loading: false } };
    case SET_DRIVER_COACHING_DATA:
      const { driverData } = action;
      return { ...state, masterData: { ...state.masterData, driverCoaching: driverData } };
    default:
      return state;
  }
};

export default app;
