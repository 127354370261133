import { io, Socket } from 'socket.io-client';
import { wsBaseUrl } from 'config';
import { isNotificationAllowed } from 'utils/uiHelper';
import { v4 as uuidv4 } from 'uuid';

import {
  ODD_REQUEST_UPDATE,
  ESTABLISHED_WS_CONNECTION,
  ESTABLISHED_WS_CONNECTION_SUCCESS,
  OPEN_SENSOR_DATA_REQUEST,
  OPEN_EVENT_DATA_REQUEST,
  CLOSE_EVENT_DATA_REQUEST,
  UPDATE_SENSOR_DATA,
  UPDATE_EVENT_DATA,
  NOTIFICATION_DATA,
  CLOUD_TELEPHONY,
  CLOUD_TELEPHONY_DEVICE_STATUS,
  LIVE_STREAMING,
  LIVE_STREAMING_STATUS,
  COMMAND_HANDLER,
  SERVICE_HANDLER,
  SERVICE_HANDLER_STATUS,
  COMMAND_HANDLER_STATUS,
  ACTIONABLE_EVENT
} from 'redux/actions/details/actionTypes';
import { oddRequestUpdate, eventStatus, incidentStatus, WebSocketNotificationEvents} from 'socket/eventType';

export let socket = Socket;

const socketMiddleware = (store) => {
  // let socket = Socket;
  return (next) => (action) => {
    const { type, socketEvent, requestOptions, toSubscribe } = action;
    const { app } = store?.getState();
    const isConnectionEstablished = socket && app?.socket?.isConnected;
    const accessToken = JSON.parse(localStorage.getItem('user'))?.accessToken ?? '';
    const clientId = JSON.parse(localStorage.getItem('user'))?.user?.clientId ?? '';
    
    switch (type) {
      case ESTABLISHED_WS_CONNECTION:
        if (!isConnectionEstablished) {
          socket = io(wsBaseUrl, {
            extraHeaders: { authorization: `Bearer ${accessToken}` },
          });

          socket.on('connect', () => {
            const requestOptions = { 
              incidentDataRequest: 'open',
            };

            if (isNotificationAllowed(clientId)) {
              socket.emit(incidentStatus, requestOptions);
              socket.emit(eventStatus,{eventDataRequest: 'open'});
            }

            store.dispatch({ type: ESTABLISHED_WS_CONNECTION_SUCCESS, isConnected: true });
            
            socket.on(oddRequestUpdate, (response) => {
              const oddRequestsStatus = [...(app?.preview?.oddRequestsStatus ?? [])];
              const indexOfOddRequest = oddRequestsStatus?.findIndex(
                (oddRequest) => oddRequest?.id === response?.id,
              );

              if (indexOfOddRequest !== -1) {
                oddRequestsStatus[indexOfOddRequest]['requestStatus'] = response?.requestStatus;
              } else {
                oddRequestsStatus.push(response);
              }
              store.dispatch({ type: ODD_REQUEST_UPDATE, oddRequestsStatus });
            });

            socket.on(WebSocketNotificationEvents.NEW_EVENT, (eventUpdate) => {
              // store.dispatch({ type: UPDATE_EVENT_DATA, eventUpdate });
              store.dispatch({ type: NOTIFICATION_DATA, notification: eventUpdate});
            });
            socket.on(WebSocketNotificationEvents.NEW_ALERT, (alertUpdate) => {
              store.dispatch({ type: NOTIFICATION_DATA, notification: alertUpdate});
            });
            socket.on(WebSocketNotificationEvents.UNAUTHORIZED_DRIVER, (unknownDriverInfo) => {
              store.dispatch({ type: NOTIFICATION_DATA, notification: unknownDriverInfo});
            });
            socket.on(WebSocketNotificationEvents.GEOFENCE, (geoFenceData) => {
              store.dispatch({ type: NOTIFICATION_DATA, notification: geoFenceData});
            });
            socket.on(WebSocketNotificationEvents.ACTIONABLE_EVENT, (actionableEvent) => {
              store.dispatch({ type: ACTIONABLE_EVENT, actionableEvent: actionableEvent});
            });
          });
          socket?.on(incidentStatus,(incidentUpdate) => {
           store?.dispatch({type: NOTIFICATION_DATA, notification: incidentUpdate});
          });
          socket.on('disconnect', () => {
            socket?.removeAllListeners();
          });
        }
        break;
      case OPEN_SENSOR_DATA_REQUEST:
        {
          if (isConnectionEstablished) {
            socket.emit(socketEvent, requestOptions);
            socket.on(socketEvent, (sensorData) => {
              const selectedVehicleForTracking = store?.getState().app?.tracking?.selectedVehicle;
              if (sensorData[0]?.vehicleId === selectedVehicleForTracking) {
                store.dispatch({ type: UPDATE_SENSOR_DATA, sensorData }); 
              }
            });
          }
        
          break;
        }
      case CLOUD_TELEPHONY:
        {
          if (isConnectionEstablished) {
            socket.emit(socketEvent, {...requestOptions, uniqueId: uuidv4()});
            socket.on(socketEvent, (deviceData) => {
              let jsonData;
              if (typeof deviceData === 'string') {
                jsonData = JSON.parse(deviceData)
              }
              else {
                jsonData = deviceData;
              }
              const selectedVehicleForTracking = store?.getState().app?.callDetails?.vehicleId
              if (jsonData?.vehicleId === selectedVehicleForTracking) {
                store.dispatch({ type: CLOUD_TELEPHONY_DEVICE_STATUS, deviceData:{...jsonData, updatedAt: new Date().getTime()} }); 
              }
            });
          }
          break;
        }
        case LIVE_STREAMING:
        {
          if (isConnectionEstablished) {
            socket.emit(socketEvent,  {...requestOptions, uniqueId: uuidv4()});
            socket.on(socketEvent, (callData) => {
              let jsonData;
              if (typeof callData === 'string') {
                jsonData = JSON.parse(callData)
              }
              else {
                jsonData = callData;
              }
              store.dispatch({ type: LIVE_STREAMING_STATUS, data: { ...jsonData, updatedAt: new Date().getTime() } });
            });
          }
          break;
        }
        case SERVICE_HANDLER:
        {
          if (isConnectionEstablished) {
            socket.emit(socketEvent,  {...requestOptions});
            if (toSubscribe) {
              socket.on(socketEvent, (deviceData) => {
                let jsonData;
                if (typeof deviceData === 'string') {
                  jsonData = JSON.parse(deviceData)
                }
                else {
                  jsonData = deviceData;
                }
                store.dispatch({ type: SERVICE_HANDLER_STATUS, serviceHandlerData: { ...jsonData, updatedAt: new Date().getTime() } });
              });
            }
          }
          break;
        }
        case COMMAND_HANDLER:
        {
          if (isConnectionEstablished) {
            socket.emit(socketEvent,  {...requestOptions});
            if (toSubscribe) {
              socket.on(socketEvent, (deviceData) => {
                let jsonData;
                if (typeof deviceData === 'string') {
                  jsonData = JSON.parse(deviceData)
                }
                else {
                  jsonData = deviceData;
                }
                store.dispatch({ type: COMMAND_HANDLER_STATUS, commandHandlerData: { ...jsonData, updatedAt: new Date().getTime() } });
              });
            }
          }
          break;
        }
      case CLOSE_EVENT_DATA_REQUEST:
        if (isConnectionEstablished) {
          socket.emit(socketEvent, requestOptions);
          socket.off(socketEvent);
        }
        break;
      default:
        break;
    }

    if (isConnectionEstablished && socketEvent) {
      //TODO: can emit event or listen event after app load
    }
    next(action);
  };
};

export default socketMiddleware;
